import React, { useState } from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import ScrollAnimation from 'react-animate-on-scroll';

import "./RoomDetails.scss"
const RoomDetails = (props) => {
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)
        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        )
        const isCurrentEventKey = currentEventKey === eventKey
        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-up" : "icon-down"
                    }
                ></i>
            </button>
        )
    }
    
    var floor_one = ''
    var floor_two = ''
    var floor_three = ''
    var floor_four = ''


    let floor_name = props.property_floor && props.property_floor.length
    // if(floor_name == 1) {
        floor_one = props.property_floor && props.property_floor.filter(item => item.id == 1).pop();
        floor_two = props.property_floor && props.property_floor.filter(item => item.id == 2).pop();
        floor_three = props.property_floor && props.property_floor.filter(item => item.id == 3).pop();
        floor_four = props.property_floor && props.property_floor.filter(item => item.id == 4).pop();
        floor_one = floor_one ? floor_one.name : 'Ground Floor'
        floor_two = floor_two ? floor_two.name : 'First Floor'
        floor_three = floor_three ? floor_three.name : 'Second Floor'
        floor_four = floor_four ? floor_four.name : 'Third Floor'
    // }

    return (
        <section className="room-details">
            <h2>Room Dimensions</h2>
            <Accordion >
                {props.features?.floors >= 1 &&
                    <Card>
                        <div className="accordion-header card-header">
                            <ContextAwareToggle eventKey="0" className="accordion-header">
                                <h4>{floor_one}</h4>
                            </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey="0">
                            <div className="accordion-card accordion-card-first card-body">

                                <div className="accordion-list">
                                    {props.room_details && props.room_details.map((data, i) => {
                                        return (
                                            <React.Fragment>
                                                {data.floor_id == 1 &&
                                                    <div className="list-wrapper">
                                                        <span>{data.room_name}</span>
                                                        {data.room_dimensions_text &&
                                                            <span>{data.room_dimensions_text}</span>
                                                        }
                                                    </div>
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                                </div>

                            </div>
                        </Accordion.Collapse>
                    </Card>
                }

            </Accordion>
            <Accordion className="second">
                {props.features?.floors >= 2 &&
                    <Card>
                        <div className="accordion-header card-header">
                            <ContextAwareToggle eventKey="1" className="accordion-header">
                                <h4>{floor_two}</h4>
                            </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey="1">
                            <div className="accordion-card accordion-card-first card-body">

                                <div className="accordion-list">
                                    {props.room_details && props.room_details.map((data, i) => {
                                        return (
                                            <React.Fragment>
                                                {data.floor_id == 2 &&
                                                    <div className="list-wrapper">
                                                        <span>{data.room_name}</span>
                                                        {data.room_dimensions_text &&
                                                            <span>{data.room_dimensions_text}</span>
                                                        }
                                                    </div>
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                                </div>

                            </div>
                        </Accordion.Collapse>
                    </Card>
                }

            </Accordion>
            <Accordion className="third">
                {props.features?.floors >= 3 &&
                    <Card>
                        <div className="accordion-header card-header">
                            <ContextAwareToggle eventKey="2" className="accordion-header">
                                <h4>{floor_three}</h4>
                            </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey="2">
                            <div className="accordion-card accordion-card-first card-body">
                                <div className="accordion-list">
                                    {props.room_details && props.room_details.map((data, i) => {
                                        return (
                                            <React.Fragment>
                                                {data.floor_id == 3 &&
                                                    <div className="list-wrapper">
                                                        <span>{data.room_name}</span>
                                                        {data.room_dimensions_text &&
                                                            <span>{data.room_dimensions_text}</span>
                                                        }
                                                    </div>
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                                </div>

                            </div>
                        </Accordion.Collapse>
                    </Card>
                }
            </Accordion>
            <Accordion className="second">
                {props.features?.floors >= 4 &&
                    <Card>
                        <div className="accordion-header card-header">
                            <ContextAwareToggle eventKey="3" className="accordion-header">
                                <h4>{floor_four}</h4>
                            </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey="3">
                            <div className="accordion-card accordion-card-first card-body">
                                <div className="accordion-list">
                                    {props.room_details && props.room_details.map((data, i) => {
                                        return (
                                            <React.Fragment>
                                                {data.floor_id == 4 &&
                                                    <div className="list-wrapper">
                                                        <span>{data.room_name}</span>
                                                        {data.room_dimensions_text &&
                                                            <span>{data.room_dimensions_text}</span>
                                                        }
                                                    </div>
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                                </div>

                            </div>
                        </Accordion.Collapse>
                    </Card>
                }
            </Accordion>
        </section>
    )
}
export default RoomDetails
