import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slider from "react-slick";
import PlayVideo from '../../../components/Play/VimeoPlay';
import ScrollAnimation from 'react-animate-on-scroll';
import NoImage from "../../../images/douglas-no-image.png"

import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import "./Banner.scss"
const Banner = (props) => {

  const settings = {
    dots: false,
    speed: 800,
    infinite: props.propertyData.images.length > 2 ? true : false,
    arrows: true,
    mobileFirst: true,
    centerMode: false,
    draggable:false,  
    responsive: [
      {
        breakpoint: 3020,
        settings: {
          slidesToShow: 2.2,

        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2.1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const [isPlay, setPlay] = useState(false);

  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openPropertyImage = (e, ind) => {
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  }
  // Property images lightbox


  // Property FloorPlan images lightbox
  const [photoIndexFloor, setPhotoIndexFloor] = useState(0);
  const [isOpenFloor, setIsOpenFloor] = useState(false);

  const openPropertyImageFloor = (e, ind) => {
    e.preventDefault();
    setPhotoIndexFloor(0);
    setIsOpenFloor(true);
  }
  // Property FloorPlan images lightbox


  const propertyImages = props?.propertyData?.images
  const propertyImagesFloor = props.propertyData.floorplan

  // ggfx
  let processedImages = JSON.stringify({});
  if (props.propertyData?.imagetransforms?.images_Transforms) {
    processedImages = props.propertyData.imagetransforms.images_Transforms;
  }
  // ggfx

  // Property details images lightbox
  var propertyLightImages1 = propertyImages && propertyImages.map(img => img.url !== undefined && img.url !== false && img.url);
  var propertyLightImagesCaption1 = propertyImages && propertyImages.map(img => img.caption !== undefined && img.caption !== false && img.caption);

  // Removing undefined in array
  const propertyLightImages = propertyLightImages1.filter(elm => elm);
  const propertyLightImagesCaption = propertyLightImagesCaption1.filter(elm => elm);
  // Removing undefined in array

  var propertyLightImagesFloor = propertyImagesFloor && propertyImagesFloor.map(img => img.url);
  // Property details images lightbox

  var tour_video = false
  var tour_video_url = ''
  if (props.propertyData.virtual_tour?.length > 0) {
    tour_video_url = String(props.propertyData.virtual_tour[0].feedURL)
    let vimeo = tour_video_url.indexOf("vimeo.com") > -1
    if (vimeo == true) {
      tour_video = true
    }
    else {
      tour_video = false
    }
  }

  const ImageRender = ({ image_url, node, processedImages, caption }) => {
    return (
      <ImageTransform imagesources={image_url} renderer="srcSet" imagename="property.images.detail" attr={{ alt: caption , class: '' }} imagetransformresult={processedImages} id={node.id} ggfxName="FEED" />
    )
  }


  return (
    <React.Fragment>
      {propertyLightImages.length > 0 &&
        <section className="detail-banner">
          <Slider {...settings} >
            {
              propertyLightImages && propertyLightImages.map((node, i) => {
                let processedImages = JSON.stringify({});
                if (props.propertyData?.imagetransforms?.images_Transforms) {
                  processedImages = props.propertyData.imagetransforms.images_Transforms;
                }

                return (
                  <React.Fragment>
                    {node &&
                      <div className="banner-img" onClick={(e) => openPropertyImage(e, i)}>
                        <picture>
                          <ImageRender processedImages={processedImages} caption={`${propertyLightImagesCaption1 ? propertyLightImagesCaption1[i]+' - ' : ''}${props.propertyData.display_address} - Douglas Allen`} image_url={node} node={props.propertyData} />
                          {/* <ImageTransform
                          imagesources={node}
                          renderer="srcSet"
                          imagename="property.images.detail"
                          attr={{ alt: node.caption, class: '' }}
                          ggfxName="FEED"
                          imagetransformresult={processedImages}
                          id={props.propertyData.id}
                        /> */}
                        </picture>
                      </div>}
                  </React.Fragment>
                )
              })}

          </Slider>
          <div className="banner-info">
            <div className="link-wrapper">
              {
                propertyImages.length > 0 &&
                <a href="javascript:void(0)" onClick={(e) => openPropertyImage(e, 0)}>
                  <i className="zoom-out"></i>
                  {photoIndex+1}/{propertyLightImages.length}
                </a>
              }

              {propertyImagesFloor.length > 0 &&
                <a href="javascript:void(0)" onClick={(e) => openPropertyImageFloor(e, 1)}>
                  <i className="floor-plan"></i>
                  <span className="d-none d-md-block">Floorplan</span>
                </a>
              }
              {/* <Link href="#">
                <i className="icon-video">
                </i>
                <span className="d-none d-md-block">Video Walk Round </span>
              </Link> */}
              {props.propertyData.brochure?.length > 0 && props.propertyData.brochure[0].srcUrl &&
                <a href={props.propertyData.brochure[0].srcUrl} target="_blank">
                  <i className="icon-book">
                  </i>
                  <span className="d-none d-md-block">Brochure</span>
                </a>
              }
              {props.propertyData.virtual_tour?.length > 0 &&
                <React.Fragment>
                  {tour_video ?
                    <PlayVideo title={props.propertyData.title} url={props.propertyData.virtual_tour[0].feedURL} /> :
                    <a href={props.propertyData.virtual_tour[0].feedURL} target="_blank">
                      <i className="icon-play-blue"></i>
                      <span className="d-none d-md-block">Virtual Tour</span>
                    </a>
                  }
                </React.Fragment>
              }
            </div>

          </div>
          {/* {props.propertyData.virtual_tour?.length > 0 &&
            <a type="button" href={props.propertyData.virtual_tour[0].feedURL} target="_blank" className="btn-play d-none d-lg-flex">
              <i className="icon-play-blue"></i>
              Virtual Tour
            </a>
          } */}
        </section>
      }
      {/* Property image lightbox */}
      {isOpen && (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          imageTitle={propertyLightImagesCaption[photoIndex]+ ' - ' +props.propertyData.display_address}
          nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
          prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )}
      {/* Property image lightbox */}

      {/* Property FloorPlan image lightbox */}
      {isOpenFloor && propertyImagesFloor.length != 0 && (
        <Lightbox
          mainSrc={propertyLightImagesFloor[photoIndexFloor]}
          nextSrc={propertyLightImagesFloor.length > 1 && propertyLightImagesFloor[(photoIndexFloor + 1) % propertyLightImagesFloor.length]}
          prevSrc={propertyLightImagesFloor.length > 1 && propertyLightImagesFloor[(photoIndexFloor + propertyLightImagesFloor.length - 1) % propertyLightImagesFloor.length]}
          onCloseRequest={() => setIsOpenFloor(false)}
          onMovePrevRequest={() =>
            setPhotoIndexFloor((photoIndexFloor + propertyLightImagesFloor.length - 1) % propertyLightImagesFloor.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndexFloor((photoIndexFloor + 1) % propertyLightImagesFloor.length)
          }
        />
      )}
      {/* Property FloorPlan image lightbox */}

    </React.Fragment>
  )
}
export default Banner
