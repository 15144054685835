
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import _ from "lodash"
function PropertyStructuredData({ property, pageurl }) {
  const cloud_url = process.env.GATSBY_CLOUD_URL;
  const site_url = process.env.GATSBY_SITE_URL; 
  var ldJson = {
    "@context": "http://schema.org",
    "@type": property.department == "residential" ? "Residence" : property.department,
    "address" : property.display_address,
    "description" : property.accommodation_summary,
    "name" :  `${property.bedroom} bed ${property.building ? property.building+' ' : ''} in ${property.display_address}`,
    "photo" :  property.images[0]?.url,
    "mainEntityOfPage": {
      "@type": "ItemPage",
      "@id": `${pageurl}`
    }
  };
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}


export default PropertyStructuredData
